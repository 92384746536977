.Form {
    max-width: 50%;
    margin: 60px auto;
    box-shadow: 1px 1px 38px #b5b5b5;
    padding: 60px;
    border-radius: 5px;
    label {
        color: #5c5c5c;
    }
    input {
        border-radius: 5px !important;
        &:focus {
            border-radius: 5px !important;
        }
    }
}
