.Admin {
    .Add_Btn {
        display: flex;
        flex-direction: row-reverse;
        padding: 8px 23px;
    }
    .Edit_Container {
        display: flex;
        .Custom__button {
            padding: 5px 10px;
            font-size: 13px;
            margin: 4px 1px;
            color: #ffffff;
            i {
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }
    table th,
    table td {
        width: 22%;
        .locationElips {
            white-space: nowrap;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    table th .theadTitleContent {
        padding: 6px 2px;
    }
}

.locationDrop {
    padding: 0 10px;
    max-height: 300px;
    border: 1px solid #ced4da;
    overflow: auto;
    .locationFlex {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}
@media (max-width: 768px) {
    .Edit_Container .contianerType .containerButton {
        font-size: 11px;
        width: max-content;
    }
    .Edit_Container .editSection .editButton {
        font-size: 11px;
        width: max-content;
    }
    .Edit_Container .deleteSection .deleteBtn {
        font-size: 11px;
        width: max-content;
    }
    .Admin .Add_Btn .AddAdmin {
        font-size: 11px;
        width: max-content;
    }
    table {
        th,
        td {
            padding: 6px;
            font-size: 14px;
        }
    }
}

@media (max-width: 768px) {
    .table__pagination__container {
        flex-direction: column;
        margin: 20px 0;
        ul {
            li {
                a {
                    padding: 3px 7px;
                }
            }
        }
        .pagination__select {
            margin-top: 10px;
        }
    }

    .pagination_section .table__pagination__container ul li a {
        padding: 2px 8px;
    }
}
@media (min-width: 768px) and (max-width: 1240px) {
    .Admin .Edit_Container .Custom__button {
        font-size: 11px;
        width: max-content;
    }
}
