$DEFALUT_COLOR: #58666e;
$WHITE_COLOR: white;
$DEFALUT_COLOR: #58666e;
.CustomDropdown {
    &__header {
        margin-bottom: 10px;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #58666e;
    }

    &__toggle {
        color: $DEFALUT_COLOR !important;
        background-color: #fff !important;
        border: 1px solid #ccc !important;
        width: 100%;
        border-radius: 0 !important;
        padding: 6px !important;

        & {
            display: flex !important;
            justify-content: space-between;
        }
        &::after {
            margin: 10px;
            color: black;
        }
        & > .active {
            background-color: white;
            color: white;
        }

        &:hover,
        &:focus {
            background-color: $DEFALUT_COLOR;
            border-color: $DEFALUT_COLOR;
        }
    }

    &-menu {
        font-size: 14px;
    }
    &-item {
        &:hover,
        &:focus {
            background-color: #f8f9fa;
        }
    }
    .dropdown-menu.show {
        width: 100%;
        padding: 10px 15px;
        max-height: 170px;
        overflow: auto;
    }
}
.Disable {
    cursor: not-allowed;
}
